.nopadding{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.center{
  text-align: center;
}

.link-wrapper{
  padding: 16;
  background: rgba(0, 0, 0, .4);
  max-width: 500px;
  margin: auto;
  transition: all .2s ease-in-out;
  opacity: .7;
}
.featured-home iframe{
  max-width: 100%;
}
.link-wrapper:hover{
  opacity: 1;
  transform: scale(1.1)
}

.link-wrapper h3{
  border: solid 2px #fff;
  color: #fff;
  text-decoration: none;
}

.link-tag h3{
  padding: 8px;
  color: #fff;
}
.link-tag:hover{
  color: #ccc;
  text-decoration: none;
}