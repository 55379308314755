.release-item{
  margin-bottom: 20px;
}
.release-item img{
  width: 100%;
}
.release-meta{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  background: #000;
}
#releases-wrap{
  padding: 15px 0;
  background: #efefef;  
  border-radius: 5px;
}
.release-title{
  background: #ec7655;
  color: #fff;
  font-weight: 800;
  padding: 5px 7px;
  margin: 0;
}
.release-date{
  color: #fff;
  font-weight: 800;
  padding: 5px 7px;
  margin: 0;
}
.release-date.released{
  background: #5e52f7;
}
.release-date.upcoming{
  background: #3fa96a;
}