.featured-home{
  max-width: 768px;
  width: 100%;
  margin: 60px auto 40px;
}

#playlist{
  background: #0e141e;
  color: #fff;
  display: flex;
  justify-content: center;
}
.playlist-item{
  list-style: none;
}

.playlist-item-wrap{
  padding: 20px;
}
@media screen and (min-width: 650px){
  .playlist-item{
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 649px){
  .playlist-item-wrap{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .playlist-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .outbound-song-buttons{
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px){
  #playlist{
    padding: 40px 10px;
  }
  /* .song-number{
    position: absolute;
    left: -10px;
    top: -7px;
  } */
  .outbound-song-buttons{
    align-self: flex-end;
  }
  .mobile{
    display: block;
  }
  .desktop{
    display: none !important;
  }
  .playlistWrapper{
    width:100%;
  }
  .playlist-item{
    align-items: flex-start;
  }
  .playlist-item-wrap{
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  .playlist-item-wrap.iframe-loaded{
    padding: 20px
  }
  .playlist-item-wrap:hover{
    background: rgba(255,255,255,0.03);
  }
  .playlist-item .fa-play-circle,
  .playlist-item .fa-pause-circle,
  .playlist-item .fa-spinner
  {
    position: absolute; 
    transition: opacity .2s ease-in-out;
    animation-delay: .3s;
    opacity: 0
  }
  .playlist-item img{
    transition: opacity .2s ease-in-out;
    opacity: 1
  }
  .playlist-item-wrap:hover img{
    opacity: .6
  }
  .playlist-item-wrap:hover .fa-play-circle,
  .playlist-item-wrap:hover .fa-pause-circle,
  .playlist-item:hover .fa-spinner,
  {
    opacity: 1
  }
  .playlist-item button{
    transition: all .2s ease-in-out;
  }

  .playlist-item button:hover{
    opacity: .6;
  }

  .playlist-item div{
    display: flex;
    align-items: center;
  }
  .spotify-button{
    background: #1db954;
    color: #fff;
    border: none;
    padding: 8px 20px;
    border-radius: 50px;
    margin-right: 20px;
  }
  .soundcloud-button{
    background: #f70;
    color: #fff;
    border: none;
    padding: 8px 20px;
    border-radius: 50px;
  }
  .song-image{
    max-width: 35px;
  }
  .song-image-wrap{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  .song-meta-wrap{
    width: 100%;
    font-size: 18px;
  }

}

@media screen and (min-width: 768px){
  .mobile{
    display: none;
  }
  .desktop{
    display: block;
  }
  .playlist-item{
    max-width: 968px;
    display: flex;
    min-width: 768px
  }
  .playlist-item-wrap{
    transition: all .2s ease-in-out;
    cursor: pointer;
    padding: 20px
  }
  .playlist-item-wrap.iframe-loaded{
    padding: 20px
  }

  .playlist-item-wrap:hover{
    background: rgba(255,255,255,0.03);
  }
  .playlist-item .fa-play-circle,
  .playlist-item .fa-pause-circle,
  .playlist-item .fa-spinner
  {
    position: absolute;
    transition: opacity .2s ease-in-out;
    animation-delay: .3s;
    opacity: 0
  }
  .playlist-item img{
    transition: opacity .2s ease-in-out;
    opacity: 1
  }
  .playlist-item-wrap:hover img{
    opacity: .6
  }
  .playlist-item-wrap:hover .fa-play-circle,
  .playlist-item-wrap:hover .fa-pause-circle,
  .playlist-item:hover .fa-spinner
  {
    opacity: 1
  }
  .playlist-item button{
    transition: all .2s ease-in-out;
  }

  .playlist-item button:hover{
    opacity: .6;
  }

  .playlist-item div{
    display: flex;
    align-items: center;
  }
  .spotify-button{
    background: #1db954;
    color: #fff;
    border: none;
    padding: 8px 20px;
    border-radius: 50px;
    margin-right: 20px;
  }
  .soundcloud-button{
    background: #f70;
    color: #fff;
    border: none;
    padding: 8px 20px;
    border-radius: 50px;
  }
  .song-image{
    max-width: 35px;
  }
  .song-image-wrap{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  .song-meta-wrap{
    width: 100%;
    font-size: 18px;
  }
}

@media screen and (max-width: 767px){
  #playlist{
    flex-direction: row;
    display: flex;
  }
}