header{
  padding: 20px;
}
header.no-menu{
  width: 100%;
  position: fixed;
  top: 0;
}
header img{
  width: 150px;
}