#soundcloud-player{
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  background: #04070b;
  color: #fff;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
}
#player-song-image{
  max-width: 30px;
  margin-right: 15px;
}
#player-song-image img{
  width: 100%;
}
#player-meta-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  transition-delay: .2s;
}
#player-meta-wrap .fas{
  margin-left: 15px;
  cursor: pointer;
  transition: all .3s ease-in-out;
}
#player-meta-wrap .fas:hover{
  transform: scale(1.15)
}

#play-song-time{
  padding: 5px 8px;
  background: rgba(255,255,255,0.1);
  border-radius: 3px;
  margin-left: 15px
}


#player i {
	position: absolute;
	margin-top: -6px;
	color: #666;
}
	
#player i.fa-volume-down {
	margin-left: -8px;
}

#player i.fa-volume-up {
	margin-right: -8px;
	right: 0;
}

#volume {
	position: absolute;
	left: 24px;
	margin: 0 auto;
	height: 5px;
	width: 300px;
	background: #555;
	border-radius: 15px;	
}

#volume .ui-slider-range-min {
		height: 5px;
		width: 300px;
		position: absolute;
		background: #f00;
		border: none;
		border-radius: 10px;
  	outline: none;
	}
	
#volume .ui-slider-handle {
		width: 20px;
  	height: 20px;
		border-radius: 20px;
  	background: #FFF;
  	position: absolute;
  	margin-left: -8px;
  	margin-top: -8px;
  	cursor: pointer;
  	outline: none;
	}