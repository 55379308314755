.main-button{
  background: #fff;
  color: #373a42;
  border: solid 3px #373a42;
  border-radius: 5px;
  padding: 5px 10px;
  transition: opacity .15s ease-in-out;
  &.red{
    color: #d00;
    border-color: #d00;
  }
  &.green{
    color: #3fa96a;
    border-color: #3fa96a;
  }
}
.main-button:hover{
  opacity: .6;
}
.main-button.white{
  border-color: #fff;
  color: #fff;
  background: transparent;
}
.main-button.success{
  border: solid 0px #373a42;
  background: #3fa96a;
  color: #fff;
}
.main-button.fail{
  border: solid 0px #373a42;
  background: #ec7655;
  color: #fff;
}
.main-button.primary{
  border: solid 0px #373a42;
  background: #52c1f7;
  color: #fff;
}
.main-button.purp{
  border: solid 0px #373a42;
  background: #5e52f7;
  color: #fff;
}
.main-button.grey{
  border: solid 0px #373a42;
  background: #373a42;
  color: #fff;
}
.spinning-animation{
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}