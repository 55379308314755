.popup-container .main-button{
  width: 100%;
}
.calendar-wrap{
  position: absolute;
  z-index: 10;
  overflow: hidden;
}
.show-calendar{
  height: unset;
}
.hide-calendar{
  height: 0;
}