.gallery-image-wrap{
  margin-bottom: 20px;
}
.gallery-image-wrap .meta{
  color: #ccc; /* rgb(128, 0, 255); */
  text-decoration: none;
  background: rgba(128, 0, 255, .2);
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.gallery-image-wrap.unlocked .meta{
  color: #ccc; /* rgb(128, 0, 255); */
  text-decoration: none;
  background: rgba(0,106,41,0.4);
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.gallery-image-wrap h3,
.gallery-image-wrap h4{
  margin: 0;
  padding: 5px 15px;
}
.gallery-image-wrap h3{
  padding-top: 15px;
}
.gallery-image-wrap h4{
  padding-bottom: 15px;
}
.gallery-image-wrap img{
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}