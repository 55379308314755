.social-wrap{
  position: fixed;
  width: 60px;
  padding: 10px;
  z-index: 1000;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.social-icons{
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
  transition: transform .2s ease-in-out;
  position: relative;
}

.social-icons:hover{
  cursor: pointer;
  transform: scale(1.1)
}

.social-icons.custom{
  width: 25px;
  height: 25px;
  position: relative;
  top: -4px;
  left: -2px;
}

.signout{
  opacity: .2;
  transition: all .2s ease-in-out;
}
.signout:hover{
  opacity: 1;
}