.popup-container{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  left: 0;
  background: rgba(0,0,0,0.95);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow: scroll;
  flex-direction: column;
}

.close-popup{
  position: fixed;
  padding: 20px;
  color: #fff;
  top: 20px;
  right: 0;
  font-size: 18px;
  z-index: 9999;
  cursor: pointer;
}

.new-release-inner-wrap .col-sm-6{
  margin-top: 10px
}