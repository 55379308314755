.form-wrap{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600
}

/* INPUT OUTER WRAP */
.input-outer-wrap{
  display: inline-block;
  position: relative;
  margin: 20px 10px 5px 0;
  
}
.input-label{
  position: absolute;
  transform-origin: top left;
  z-index: 0;
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  font-size: 18px;
  bottom: 0;
}
.input-element-underline-bg{
  opacity: .4;
  position: absolute;
  transition: all .3s ease-in-out;
  height: 2px;
  width: 100%;
  margin: auto;
  bottom: 0;
}
.input-element-underline{
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 2px;
  width: 0;
  margin: auto;
}
.input-element{
  background: transparent;
  border: 0;
  position: relative;
  z-index: 10;
  font-size: 18px;
}
/* REMOVE OUTLINE */
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

@media screen and (max-width: 500px){
  .input-outer-wrap{
    width: 100% !important;
    text-align: left;
  } 
  .input-element{
    
  } 
}

.loading {
  /* width: 13px;
  height: 13px; */
  margin-top: 20px;
  font-size: 32px;
  color: #fff;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}

/* The switch - the box around the slider */
.switch-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.switch-wrap span{
  font-size: 18px;
}
@media screen and (max-width: 676px){
  .switch-wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }  
}
.switch {
  position: relative;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #fe6d2c;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fe6d2c;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

button{
  cursor: pointer;
}

.settings-select{
  background: rgba(255,255,255,0.3);
  border: none;
  padding: 5px;
  color: #fff;
}