[id^="drag-and-drop-files"] {
  border: 2px dashed #ccc;
  border-radius: 20px;
  flex: 1;
  font-family: sans-serif;
  margin: 20px auto;
  padding: 20px;
  color: #fff;
  text-align: center;
  transition: all .1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
[id^="drag-and-drop-files"].highlight {
  border-color: #5e52f7;
  color: #5e52f7;
}
[id^="drag-and-drop-files"].added {
  border-width: 0
}
[id^="drag-and-drop-files"].highlight i{
  color: #5e52f7 !important;
}
[id^="fileElem"]{
  display: none !important;
}
p {
  margin-top: 0;
}
#gallery {
  margin-top: 10px;
}
#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.button:hover {
  background: #ddd;
}
i.song-upload-button{
  font-size: 42px;
  cursor: pointer;
  color: #52c1f7;
  transition: all .1s ease-in-out;
}
i.song-upload-button.added{
  font-size: 42px;
  cursor: pointer;
  color: #3fa96a;
}