

.page.signup{
  color: #82a1ac;
  background-size: cover !important;
}
.page.signup a{
  text-decoration: none;
}
.page.signup a:hover{
  text-decoration: none;
}
.signup-title{
  color: #82a1ac;
  text-align: center;
  margin: 0 0;
  font-size: 68px;
}
@media screen and (min-width: 500px){
  .signup-title{
    font-size: 80px;
  }
}
.signup-sub-title{
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 200;
}

.signup-wrap{
  padding: 10px;
  max-width: 400px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+46&0.65+0,0+57 */
  background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.13) 46%, rgba(0,0,0,0) 57%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.13) 46%,rgba(0,0,0,0) 57%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.13) 46%,rgba(0,0,0,0) 57%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  text-align: center;
}

.facebook-signup-btn{
  transition: color .3s ease-in-out;
  display: flex;
  font-size: 20px;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+46&0.65+0,0+57 */
  background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.13) 46%, rgba(0,0,0,0) 57%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.13) 46%,rgba(0,0,0,0) 57%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.13) 46%,rgba(0,0,0,0) 57%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  color: #eee;
  border: none;
  padding: 20px;
  right: -17px;
}
.facebook-signup-btn:hover{
  color: #3b5998;
  
}
.facebook-signup-btn .fb-logo{
  max-width: 20px;
  margin: 0 20px;
  transition: all .8s ease-in-out;
  position: relative;
  left: -40px;
  position: absolute;
  opacity: 1;
}

.facebook-signup-btn:hover .fb-logo{
  transform: scale(1.2);
  opacity: 0;
}

.facebook-signup-btn .fb-logo.lit{
  opacity: 0;
}

.facebook-signup-btn:hover .fb-logo.lit{
  transform: scale(1.2);
  opacity: 1;
}

.pulse {
  margin:100px;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(130, 161, 172, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(130, 161, 172, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(130, 161, 172, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(130, 161, 172, 0.4);
    box-shadow: 0 0 0 0 rgba(130, 161, 172, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(130, 161, 172, 0);
      box-shadow: 0 0 0 10px rgba(130, 161, 172, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(130, 161, 172, 0);
      box-shadow: 0 0 0 0 rgba(130, 161, 172, 0);
  }
}

