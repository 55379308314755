#contact-artist{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #777
}
.contact-header{
  margin: 0
}
@media screen and (min-width: 768px){
  #contact-artist{
    flex-direction: row;
  }
  .contact-header{
    margin-right: 40px;
  }
}
.contact-header{
  font-size: 60px;
  font-family: Montserrat;
  font-weight: 800;
}