#artist-biography{

}
#artist-biography .left{
}
#artist-biography .right{
  background: rgb(14, 20, 30);
}

@media screen and (min-width: 768px){
  #artist-biography .right{
    background: #000; /* rgb(14, 20, 30); */
  } 
}
.bio-bg{
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 10;
}
.bio-header{
  position: relative;
  z-index: 20;
  font-size: 160px;
  font-family: Montserrat;
  font-weight: 800;
}
@media screen and (max-width: 767px){
  #bio-left{
    overflow: hidden !important;
  }
  #bio-left img{
    bottom: -1px
  }
}
#bio-right{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px;
}
#bio-right p{
  font-size: 18px;
  color: #fff;
  position: relative;
  z-index: 100;
  max-width: 500px;
}

@media screen and (max-width: 767px){
  .bio-bg{
    position: absolute;
    left: unset;
    right: 0;
  }
}