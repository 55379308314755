
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(130, 161, 172, 0);
  }
}
@keyframes spin {
  0% {
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
}


@-moz-keyframes spinPulse {
  0% {
    -moz-transform: rotate(0deg) scale(.8);
    -moz-box-shadow: 0 0 0 0 rgba(130, 161, 172, 0.4);
  }
  70% {
    -moz-transform:rotate(252deg) scale(1);
    -moz-box-shadow: 0 0 0 10px rgba(130, 161, 172, 0);
  }
  100% {
    -moz-transform: rotate(360deg) scale(.8);
    -moz-box-shadow: 0 0 0 0 rgba(130, 161, 172, 0);
  }
}
@-webkit-keyframes spinPulse {
  0% {
    -webkit-transform: rotate(0deg) scale(.8);
    -webkit-box-shadow: 0 0 0 0 rgba(130, 161, 172, 0.4);
  }
  70% {
    -webkit-transform:rotate(252deg) scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(130, 161, 172, 0);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(130, 161, 172, 0);
  }
}
@keyframes spinPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(130, 161, 172, 0.4);
    transform:rotate(0deg) scale(.8);
  }
  70% {
    transform:rotate(252deg) scale(1);
    box-shadow: 0 0 0 10px rgba(130, 161, 172, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(130, 161, 172, 0);
    transform:rotate(360deg) scale(.8);
  }
}

.spinning{
  animation: spin 2s infinite linear;
  animation-delay: 0s !important;
}

.spinning-pulse{
  margin:100px;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: spinPulse 2s infinite linear;
  animation-delay: 0s !important;
}